<template>
    <section v-if="json" class="page-visionaries thefold">
        <!--<div class="bgimg" style="background-image: url(/assets/site/images/pages/visionaries/bg.jpg);"></div>
        <div class="scrim abs100"></div>-->
        <div class="scrim abs100"></div>
        <div class="capper">
            <div class="top" data-header-bg="light" data-aos="custom-fade-in">
                <div class="capper-padded-2x">
                    <div class="rte light">
                        <h1 v-html="$route.meta.title"></h1>
                        <div v-html="json.title"></div>
                    </div>
                </div>
            </div>
            <div class="principals" data-header-bg="light">
                <div class="capper-padded-2x">
                    <div class="grid">
                        <div v-for="(item, index) in json.entries" :key="`entry-${index}`" class="col" data-aos="custom-fade-in">
                            <router-link class="generic-image-hover" :to="item.url">
                                <div v-if="item.logoWithStyle" class="logo" >
                                    <div class="bgimg" :style="item.logoWithStyle"></div>
                                </div>
                                <div class="txt" :class="(item.logoWithStyle) ? 'has-logo' : 'no-logo'">
                                    <div class="rte light"><p>{{item.title}}</p></div></div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
export default {
    name: "VisionariesPage",
    components: {

    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
        }

    },
    created () {
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        axios.get((`${window.config.apiroot}visionaries${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch((e) => {
                console.error('Error',slug,e)
        })
    },
    methods: {
        reveal () {

        },
        unreveal () {

        },
    }
}
</script>
