import AOS from 'aos'
import createjs from 'preload-js'
import gsap, {Power3} from 'gsap'
const gh = {
    globalSectionInit: function() {
        setTimeout(function() {
            gh.aosInit()
        },500)
    },
    aosInit: function() {
        AOS.init({
            offset: 100,
            duration: 500,
            once: true,
        })
        gh.aosRefresh()
    },
    aosRefresh: function() {
        //AOS.refresh()
        AOS.refreshHard()
    },
    postLoadAssets: function(assets) {
        let queue = new createjs.LoadQueue()
        queue.setMaxConnections(100)
        //queue.on("progress", function(e) {console.log(e)}, this)
        //queue.on("complete", this.postloadComplete, this)
        //queue.on("error", this.postloadError, this)
        queue.loadManifest(assets)
    },
    scrollTo(route, window) {
        const tid = '#section-' + route
        gsap.to(window, {
            duration: 0.5,
            scrollTo:tid,
            easing: Power3.easeInOut
        })
    },
    enableBodyScroll(enable) {
        if (enable) {
            document.body.classList.remove("no-scroll")
            window.scrollTo(0, gh.bodyScrollY)
            document.body.style.top = 0
        } else {
            gh.bodyScrollY = window.scrollY
            document.body.classList.add("no-scroll")
            document.body.style.top = -gh.bodyScrollY+'px'
        }
    },
    stripSlashesFrontAndBack(val) {
        if (val) {
            val = val.toString()
            if(val.substr(-1) === '/') {
                val = val.substr(0, val.length - 1);
            }
            if(val.substr(0,1) === '/') {
                val = val.substr(1, val.length);
            }
        }
        return val

    },
    genericTextWrapper(html) {
        if (html) {
            // Create a temporary DOM element to manipulate the HTML string
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;

            // Find all oembed elements within the temporary DOM
            const oembedElements = tempDiv.querySelectorAll('oembed[url]');

            oembedElements.forEach(oembed => {
                // Get the URL from the oembed element
                const url = oembed.getAttribute('url');

                // Create an iframe element
                const iframe = document.createElement('iframe');
                iframe.width = "560";  // Set the width of the iframe
                iframe.height = "315"; // Set the height of the iframe
                iframe.src = url.replace("watch?v=", "embed/"); // Convert the YouTube URL to embed format
                iframe.frameBorder = "0"; // Remove the frame border
                iframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"; // Set allow attributes
                iframe.allowFullscreen = true; // Allow fullscreen

                // Replace the oembed element with the iframe
                oembed.parentNode.replaceChild(iframe, oembed);
            });

            // Return the modified HTML as a string
            return tempDiv.innerHTML;
        } else {
            return null
        }

    }
}
export default gh
